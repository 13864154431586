import { getBaseUrl } from "./apiHelper";
import { AttachedProfilePostModel } from "../models/AttachedProfilePostModel";
import { RespondentDistributionModel } from "../models/RespondentDistributionModel";
import { SubToolEnum } from "../models/enums/subToolEnum";
import { SelectItemModel } from "../models/SelectItemModel";
import { SettingsAccessModel } from "../models/settings/SettingsAccessModel";
import { ProfileSharingModel } from "../models/users/ProfileSharingModel";
import { SettingsQuestionnaireModel } from "../models/settings/SettingsQuestionnaireModel";
import { CreateRepondentReponseModel } from "../models/profileCrud/CreateRepondentReponseModel";
import { NoteModel } from "../models/NoteModel";
import { MultiRespondentEditModel } from "../models/tags/MultiRespondentEditModel";
import { TagEnum } from "../models/enums/TagEnum";
import { PluralityEnum } from "../models/enums/pluralityEnum";
import { NewUsersSharingModel} from "../models/NewUsersSharingModel";
import { ToolEnum } from "../models/enums/toolEnum";
import { UserRoleModel } from "../models/users/UserRoleModel";
import { MultiSharingPostModel } from "../models/MultiSharingPostModel";
import {ToolCrudModel} from "../models/profileCrud/ToolCrudModel";
import { UserInformationPostModel } from "../models/userProfile/UserInformationPostModel";
import { CopDashboardModel } from "../models/information/copDashboardModel";
import { RespondentExistingModel } from "../models/profileCrud/RespondentExistingModel";
import {MergeRespondentsPostModel} from "../models/respondent/MergeRespondentsPostModel";
import {RespondentProfilesSplitViewModel} from "../models/respondent/RespondentProfilesSplitViewModel";
import {SplitRespondentProfilePostModel} from "../models/respondent/SplitRespondentProfilePostModel";
import {RespondentModel} from "../models/profileCrud/RespondentModel";
import {ProfileSettingModel} from "../models/settings/ProfileSettingModel";
import { KeyValueSettingModel } from "../models/settings/KeyValueSettingModel";
import { GroupProfileTypeEnum } from "../models/enums/groupProfileTypeEnum";
import { GroupProfileModel } from "../models/profileCrud/GroupProfileModel";
import { UserInformationModel } from "../models/respondent/UserInformationModel";
import { TransferOwnershipPostModel } from "../models/respondent/TransferOwnershipPostModel";
import {AnalysisPageEnum} from "../models/enums/analysisPageEnum";
import {DeleteNoteOtherUserModel} from "../models/DeleteNoteOtherUserModel";
import {FocusProfileModel} from "../models/FocusProfileModel";

export class GarudaApi {

    public readonly reportDownloadUrl: string = "report/ReportDownload";

    private readonly toolsEndpoint: string = "tools/GetTools";
    private readonly getAccessSettingsEndpoint: string = "setting/GetAccessSettings";
    private readonly getQuestionnaireSettingsEndpoint: string = "setting/GetQuestionnaireSettings";
    private readonly userConnectionsEndpoint: string = "userconnections/GetUserConnections";
    private readonly updateBestfriendsEndpoint: string = "userconnections/UpdateBestfriends";
    private readonly updateUsersettingEndpoint: string = "setting/PostUserSetting";
    private readonly respondentPostEndpoint: string = "respondent/Create";
    private readonly respondentUpdateEndpoint: string = "respondent/Edit";
    private readonly respondentEndpoint: string = "respondent/GetRespondent";
    private readonly grafGetEndpoint: string = "/Image/Grafs";
    private readonly grafSingleGetEndpoint: string = "/Image/SingleGraf";
    private readonly grafSingleGetBarChartData: string = "/Image/SingleBarChartGraf";
    private readonly barometerGetUrl: string = "/Image/Barometer";
    private readonly EnergyGetUrl: string = "/Image/Energy";
    private readonly EnergyWithHeaderGetUrl: string = "/Image/EnergyWithHeader";
    private readonly barometerWithHeaderGetUrl: string = "/Image/BarometerWithHeader";
    private readonly barometerGroupGetUrl: string = "/Image/BarometerGroup";
    private readonly weightChartUrl: string = "/Image/WeightChart";
    private readonly keywordChartUrl: string ="/Image/KeywordChart"
    private readonly benchmarkUrl: string ="/Image/Benchmark"
    private readonly consistencyBarGraphUrl: string = "/Image/ConsistencyBarGraph"
    private readonly consistencyBarGraphWithHeaderUrl: string = "/Image/ConsistencyBarGraphWithHeader"
    private readonly focusModelUrl: string = "/Image/FocusModel";
    private readonly miniatureFocusModelUrl: string = "/Image/MiniatureFocusModel"
    private readonly miniatureModelsUrl: string = "/Image/MiniatureModels"
    private readonly miniatureFocusModelWithHeaderUrl: string = "/Image/MiniatureFocusModelWithHeader";
    private readonly miniaturePressMatrixWithHeaderUrl: string = "/Image/MiniaturePressMatrixWithHeader";
    private readonly getNewStyleGraph: string = "/Image/NewStyle";
    private readonly getNewStyleNames: string = "/Image/NewStyleNames";
    private readonly chartWithHeaderGetUrl: string = "/Chart/ChartWithHeader";
    private readonly profilDeleteEndpoint: string = "/profile/DeleteProfile";
    private readonly profilArchiveEndpoint: string = "/profile/ArchiveProfile";
    private readonly profilWithdrawConsentArchiveEndpoint: string = "/profile/WithdrawConsent";
    private readonly getCompareModalBodyEndpoint: string = "profile/GetCompareModalBody";
    private readonly personProfilGetProfilesAttached: string = "profile/getAttachedProfiles";
    private readonly personProfilPostProfilesAttached: string = "profile/postAttachedProfiles";
    private readonly personProfilDeleteProfileAttached: string = "profile/deleteAttachedProfile";
    private readonly personProfilPostNewProfileAttached: string = "profile/addAttachedProfile";
    private readonly getAttachedProfilesFilteredEndpoint: string = "profile/GetAttachedProfilesFiltered";
    private readonly getFocusProfilesEndpoint: string = "profile/focusProfiles";
    private readonly getProfileCountForTagEndpoint: string = "profile/profilesForTag";
    private readonly patchSvgClick: string = "profile/SvgClick";
    private readonly profileInterpretationEndpoint: string = "Interpretation/InterpretationContent";
    private readonly profileSettingEndpoint: string = "Interpretation/SetOwnCommentReplacesInterpretationText";
    private readonly profileCombinedTraitsEndpoint: string = "CombinedTraits/CombinedTraitsContent";
    private readonly profileCombinedTraitsGraphUrl: string = "CombinedTraits/CombinedTraitsGraph";
    private readonly updateGenerelProfileSettingUrl: string = "Setting/PostGenerelProfileSetting";
    private readonly updateGenerelProfileSettingListUrl: string = "Setting/PostGenerelProfileSettingList";
    private readonly updateGenerelProfileSettingFromBenchmarkUrl: string = "Setting/PostGenerelProfileSettingFromBenchmarkGroup";
    private readonly languageSettingPostUrl: string = "Setting/PostSelectedLanguage";
    private readonly scaleSettingPostUrl: string = "Setting/PostSelectedScale";
    private readonly getAdjustmentModalContent: string = "Setting/GetAdjustSettingsModal";
    private readonly getCreateGroupProfileModalContent: string = "FocusGroupProfile/GetCreateGroupProfileModalContent";
    private readonly getGroupProfileEditModalContent: string = "FocusGroupProfile/GroupProfileEditModal";
    private readonly getGroupProfileEndpoint: string = "FocusGroupProfile/GetGroupProfile";
    private readonly getAdjustmentSettings: string = "Setting/GetAdjustSettings";
    private readonly getAdjustmentSetting: string = "Setting/GetAdjustSetting";
    private readonly templateListGetUrl: string = "template/GetTemplateList";
    private readonly distributeRespondentPostUrl: string = "template/DistributeRespondent";
    private readonly mergedTemplatePostUrl: string = "template/MergedTemplate";
    private readonly mergedSMSTemplatePostUrl: string = "template/MergedSMSTemplate";
    private readonly reportTypesGetUrl: string = "report/reportTypes";
    private readonly personProfileReportTypesGetUrl: string = "report/PersonProfileReportTypes";
    private readonly focusPersonReportTypesGetUrl: string = "report/FocusPersonReportTypes";
    private readonly jobProfileReportTypesGetUrl: string = "report/JobProfileReportTypes";
    private readonly focusJobProfileReportTypesGetUrl: string = "report/FocusJobProfileReportTypes";
    private readonly focusMirrorFollowupReportTypesGetUrl: string = "report/FocusMirrorFollowupReportTypes";
    private readonly groupReportContentGetUrl: string = "report/groupReportContent";
    private readonly reportTypeGetUrl: string = "report/reportDetails";
    private readonly specialReportTypeGetUrl: string = "report/specialReportDetails";
    private readonly reportRequestPostUrl: string = "report/requestReport";
    private readonly requestSendReportMailPostUrl: string = "report/requestSendReportMail";
    private readonly reportStatusUrl: string = "report/reportStatus";
    private readonly reportNotificationContentUrl: string = "report/reportNotificationContent";
    private readonly reportNotificationsUrl: string = "report/reportNotifications";
    private readonly reportNotificationsDeleteUrl: string = "report/deleteReportNotifications"
    private readonly getReportMailModalUrl: string = "report/GetSendReportMailModal";
    private readonly multiProfileSharingGetUrl: string = "ProfileSharing/MultiProfileSharing";
    private readonly removeMultiProfileSharingGetUrl: string = "ProfileSharing/RemoveMultiProfileSharing";
    private readonly profileSharingGetUrl: string = "ProfileSharing/ProfileSharing";
    private readonly shareProfileWithNewUserUrl: string = "ProfileSharing/ShareProfileWithNewUser";
    private readonly profileRolePostUrl: string = "ProfileSharing/ProfileRole";
    private readonly addProfileSharingPostUrl: string = "ProfileSharing/AddProfileSharing";
    private readonly addMultiProfileSharingPostUrl: string = "ProfileSharing/AddMultiProfileSharing";
    private readonly inviteProfileSharingUserPostUrl: string = "ProfileSharing/InviteProfileSharingUser";
    private readonly setTimeLimitProfileSharingUrl: string = "ProfileSharing/SetTimeLimit";
    private readonly setMultiTimeLimitProfileSharingUrl: string = "ProfileSharing/SetMultiTimeLimit";
    private readonly setCustomizedTimeLimitProfileSharingUrl: string = "ProfileSharing/SetCustomizedTimeLimit";
    private readonly customizeProfileSharingUrl: string = "ProfileSharing/ProfileSharingCustomizeShare";
    private readonly customizeNewUserProfileSharingUrl: string = "ProfileSharing/ProfileSharingCustomizeNewUser";
    private readonly deleteProfileSharingUrl: string = "ProfileSharing/DeleteProfileSharing";
    private readonly statementContentUrl: string = "statement/StatementContent";
    private readonly statementAnalysisInsightContentUrl: string = "statement/StatementAnalysisInsightContent";
    private readonly statementFpContentUrl: string = "FocusPersonprofile/FocusStatementAnalysisContent";
    private readonly getEditRespondentModalUrl: string = "respondent/edit";
    private readonly getEditRespondentTagsModalUrl: string = "respondent/editRespondentTagsModal";
    private readonly editRespondentsTagsUrl: string = "respondent/editRespondentsTags";
    private readonly getTransferOwnershipModalUrl: string = "respondent/TransferOwnershipModal";
    private readonly transferOwnershipUserUrl: string = "respondent/TransferOwnershipUser";
    private readonly transferOwnershipUrl: string = "respondent/TransferOwnership";
    private readonly getCompanyTagsUrl: string = "respondent/GetCompanyTags";
    private readonly getUserInformationUrl: string = "respondent/GetUserInformation";
    private readonly getExistingRespondentsUrl: string = "respondent/GetExistingRespondents";
    private readonly getRespondentProfilesToSplitUrl: string = "respondent/GetRespondentProfilesToSplit";
    private readonly splitRespondentProfilePostUrl: string = "respondent/SplitRespondentProfile";
    private readonly getRespondentListPartialUrl: string = "respondentList/respondentListPartial";
    private readonly respondentMergeModalUrl: string = "respondent/RespondentMergeModal";
    private readonly profileSelectorModalUrl: string = "respondent/ProfileSelectorModal";
    private readonly mergeRespondentsPostUrl: string = "respondent/MergeRespondents";
    private readonly getGarudaGraphModal: string = "garudaLogicRespondent/GarudaGraphModal";
    private readonly duplicateProfilePostUrl: string = "respondent/DuplicateQuestionnaire";
    private readonly getPossibleLanguagesUrl: string = "Setting/GetPossibleLanguages";
    private readonly getPossibleScalesUrl: string = "Setting/GetPossibleScales";
    private readonly getMiniatureGraphsUrl: string = "Image/MiniatureGraphs";
    private readonly createThreadNoteUrl: string = "Note/CreateThreadNote";
    private readonly deleteThreadNoteUrl: string = "Note/DeleteThreadNote";
    private readonly deleteThreadNoteOtherUserUrl: string = "Note/DeleteThreadNoteOtherUser";
    private readonly updateThreadNoteUrl: string = "Note/UpdateThreadNote";
    private readonly getGeneralNotesPartialUrl: string = "Note/GetGeneralNotesPartial";
    private readonly getNoteCountUrl: string = "Note/GetNoteCount";
    private readonly getNoteCollectionPartialUrl: string = "Note/GetNoteCollectionPartial";
    private readonly editThreadNotePartialUrl: string = "Note/EditThreadNotePartial";
    private readonly getAllNotesUrl: string = "Note/GetAllNotes";
    private readonly getPersonprofileAssociationList: string = "Profile/PersonprofileAssociationList";
    private readonly getStatementBlockUrl: string = "Statement/StatementBlock";
    private readonly getUserInformationHelperUrl: string = "userprofile/getUserInformation";
    private readonly postUserInformationUrl: string = "userprofile/PostUserInformation";
    private readonly forgotPasswordUrl: string = "account/forgotPasswordApi";
    private readonly enableTwoFactorUrl: string = "account/EnableTwoFactor";
    private readonly enableTwoFactorSendConfirmSmsUrl: string = "account/EnableTwoFactorSendConfirmSmsUrl";
    private readonly getGlobalDashboardDataUrl: string = "/GlobalDashboard/GetDashboardData";
    private readonly getGlobalDashboardAdjustPurposeModalUrl: string = "/GlobalDashboard/GetDashboardAdjustPurposeModal";
    private readonly getGlobalDashboardPurposePartialUrl: string = "/GlobalDashboard/GetDashboardPurposePartial";
    private readonly getCopDashboardDataUrl: string = "/copdashboard/GetDashboardData";
    private readonly getOnboardingProfileListPartialUrl: string = "onboarding/onboardingProfileListPartial";
    private readonly setOnboardingProfileHiddenUrl: string = "onboarding/setOnboardingProfileHidden";
    private readonly groupProfilePostEndpoint: string = "focusGroupProfile/Create";
    private readonly groupProfilePatchEndpoint: string = "focusGroupProfile/Edit";
    private readonly profilesInGroupProfileEndpoint: string = "focusGroupProfile/GroupProfiles";
    private readonly focusGroupStatementContentEndpoint: string = "focusGroupProfile/FocusGroupStatementAnalysisContent";
    private readonly focusProfileKeywordAnalysisContentEndpoint: string = "keywordAnalysis/FocusKeywordAnalysisContent";
    private readonly getJobProfileLevelsUrl: string = "jobprofil/getJobProfileLevels";
    private readonly updateJobProfileLevelUrl: string = "jobprofil/updateJobProfileLevels";
    private readonly personProfileInformationContentUrl: string = "PersonalInformation/ProfileInformationContent";
    private readonly eventLogProfileUrl: string = "EventLog/GetEventLog";
    private readonly IqTotal: string = "Image/GetIQTotal"
    private readonly IQTraits: string = "Image/IQTraits"
    private readonly reportErrorToApi: string = "Error/LogFrontEndError"

    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = getBaseUrl();
    }

    public getTools(selectedRespondentType : number) : Promise<ToolCrudModel> {
        return this.get(`${this.baseUrl}${this.toolsEndpoint}?selectedRespondentType=${selectedRespondentType}`);
    }

    public getAccessSettings() : Promise<SettingsAccessModel> {
        return this.get(`${this.baseUrl}${this.getAccessSettingsEndpoint}`);
    }

    public getQuestionnaireSettings() : Promise<SettingsQuestionnaireModel> {
        return this.get(`${this.baseUrl}${this.getQuestionnaireSettingsEndpoint}`);
    }

    public getUserConnections() : Promise<ProfileSharingModel> {
        return this.get(`${this.baseUrl}${this.userConnectionsEndpoint}`);
    }

    public updateBestfriends(userIds : string[]) : Promise<any> {
        return this.post(`${this.baseUrl}${this.updateBestfriendsEndpoint}`, JSON.stringify(userIds));
    }

    public updateQuestionnaireSetting(key: string, value: string) : Promise<any> {
        return this.post(`${this.baseUrl}${this.updateBestfriendsEndpoint}`, JSON.stringify({key, value}));
    }

    public getPossibleLanguages() : Promise<SelectItemModel[]> {
        return this.get(`${this.baseUrl}${this.getPossibleLanguagesUrl}`);
    }

    public getPossibleScales() : Promise<SelectItemModel[]> {
        return this.get(`${this.baseUrl}${this.getPossibleScalesUrl}`);
    }

    public updateUserSetting(key: string, value : string) : Promise<any> {
        return this.post(`${this.baseUrl}${this.updateUsersettingEndpoint}`, JSON.stringify({key: key, value: value}));
    }

    public getRespondent(respondentId: string, profileId: string) {
        return this.get(`${this.baseUrl}${this.respondentEndpoint}?respondentId=${respondentId}&profileId=${profileId}`);
    }

    public deleteRespondent(profileIds: string[]) {
        return this.post(this.profilDeleteEndpoint, JSON.stringify(profileIds));
    }

    public archiveProfile(profileIds: string[]) {
        return this.post(this.profilArchiveEndpoint, JSON.stringify(profileIds));
    }

    public withdrawConsentForProfile(profileIds: string[]) {
        return this.post(this.profilWithdrawConsentArchiveEndpoint, JSON.stringify(profileIds));
    }

    public getGrafData(profileList: string[],
                       grafType: string,
                       showExpandedTraits: boolean,
                       combinedNumber: boolean,
                       outerScoreIndicator: boolean,
                       fewAnswerIndicator: boolean,
                       greenScoreIndicator: boolean,
                       showContextMenu: boolean,
                       subToolEnum: SubToolEnum,
                       analysisPage: AnalysisPageEnum
    ) {

        const profileId = $("#ProfileId").val() as string;
        const profileListUrl = GarudaApi.populateProfileListUrl(profileList);

        const url: string = `${this.grafGetEndpoint}/?characteristicType=${grafType}${profileListUrl}&profileId=${profileId}&expanded=${showExpandedTraits}&combinedNumber=${combinedNumber}&outerScoreIndicator=${outerScoreIndicator}&fewAnswerIndicator=${fewAnswerIndicator}&greenScoreIndicator=${greenScoreIndicator}&showContextMenu=${showContextMenu}&subToolEnum=${subToolEnum}&analysisPage=${analysisPage}`;
        return this.get(url);
    }

    public getCircularGraph(profileList: string[]){
        const profiles = profileList.toString();

        const url: string = `${this.getNewStyleGraph}/?profiles=${profiles}&`;
        return this.get(url);
    }

    public getCircularGraphNames(profileList: string[]) {
        const profiles = profileList.toString();

        const url: string = `${this.getNewStyleNames}/?profiles=${profiles}&`;
        return this.get(url);
    }

    public getSingleGrafData(profileList: string[],
                             traitId: string,
                             showExpandedTraits: boolean,
                             caption: boolean,
                             changeScore: boolean,
                             showContextMenu: boolean,
                             subToolEnum: SubToolEnum,
                             analysisPage: AnalysisPageEnum) {

        const profileId = $("#ProfileId").val() as string;
        const profileListUrl = GarudaApi.populateProfileListUrl(profileList);

        const url: string = `${this.grafSingleGetEndpoint}/?traitId=${traitId}${profileListUrl}&profileId=${profileId}&caption=${caption}&expanded=${showExpandedTraits}&changeScore=${changeScore}&showContextMenu=${showContextMenu}&subToolEnum=${subToolEnum}&analysisPage=${analysisPage}`;
        return this.get(url);
    }

    public getMiniatureGraphs(profileId: string) {
        const url: string = `${this.getMiniatureGraphsUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getBenchmark(profileList: string[], isGroup: boolean = false, highlightedProfile: string = "", hiddenProfiles: string[] = []) {
        const profileListUrl = GarudaApi.populateProfileListUrl(profileList);
        let url: string = `${this.benchmarkUrl}?${profileListUrl}&isGroup=${isGroup}&highlightedProfile=${highlightedProfile}`;
        if (hiddenProfiles)
        {
            for(const id of hiddenProfiles)
            {
                url += `&hiddenProfiles=${id}`;
            }
        }
        return this.get(url);
    }

    public getConsistencyBarGraph(profileId: string) {
        const url: string = `${this.consistencyBarGraphUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getConsistencyBarGraphWithHeader(profileId: string) {
        const url: string = `${this.consistencyBarGraphWithHeaderUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getFocusModel(profileId: string) {
        const url: string = `${this.focusModelUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getMiniatureFocusModel(profileId: string) {
        const url: string = `${this.miniatureFocusModelUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getMiniatureModels(profileId: string) {
        const url: string = `${this.miniatureModelsUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getMiniatureFocusModelWithHeader(profileId: string) {
        const url: string = `${this.miniatureFocusModelWithHeaderUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getMiniaturePressMatrixWithHeader(profileId: string) {
        const url: string = `${this.miniaturePressMatrixWithHeaderUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getIQTraits(profileId: string, trait: string) {
        const url: string = `${this.IQTraits}/?profileId=${profileId}&trait=${trait}`;
        return this.get(url);
    }

    public getIQTotal(profileId: string) {
        const url: string = `${this.IqTotal}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getJobProfileLevels(profileId: string, trait: string) {
        const url: string = `${this.getJobProfileLevelsUrl}/?profileId=${profileId}&trait=${trait}`;
        return this.get(url);
    }

    public updateJobprofileLevel(profileId: string, trait: string, level: string) {
        const url: string = `${this.updateJobProfileLevelUrl}/?profileId=${profileId}&trait=${trait}&level=${level}`;
        return this.post(url,null);
    }

    public getStatementBlock(profileId: string, statementNumber: number) {
        const url: string = `${this.getStatementBlockUrl}/?profileId=${profileId}&statementNumber=${statementNumber}`;
        return this.get(url);
    }

    public getGeneralNotes(profileId: string) {
        const url: string = `${this.getGeneralNotesPartialUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getNoteCount(profileId: string) {
        const url: string = `${this.getNoteCountUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public getNoteCollection(profileId: string, place: string, subtool: SubToolEnum, attachedProfiles: string[]) {
        const url: string = `${this.getNoteCollectionPartialUrl}/?profileId=${profileId}&place=${place}&subTool=${subtool}&attachedProfiles=${attachedProfiles}`;
        return this.get(url);
    }

    public getAllNotes(profileId: string, subTool: SubToolEnum, attachedProfiles: string[]) {
        const url: string = `${this.getAllNotesUrl}/?profileId=${profileId}&subTool=${subTool}&attachedProfiles=${attachedProfiles}`;
        return this.get(url);
    }

    public createNote(noteModel: NoteModel) {
        return this.post(`${this.baseUrl}${this.createThreadNoteUrl}`, JSON.stringify(noteModel));
    }

    public deleteNote(noteId: number) {
        return this.post(`${this.baseUrl}${this.deleteThreadNoteUrl}`, JSON.stringify(noteId));
    }

    public deleteNoteOtherUser(dlnodeModel: DeleteNoteOtherUserModel) {
        return this.post(`${this.baseUrl}${this.deleteThreadNoteOtherUserUrl}`, JSON.stringify(dlnodeModel));
    }

    public updateThreadNote(noteModel: NoteModel) {
        return this.post(`${this.baseUrl}${this.updateThreadNoteUrl}`, JSON.stringify(noteModel));
    }

    public editThreadNotePartial(noteModel: NoteModel) {
        return this.post(`${this.baseUrl}${this.editThreadNotePartialUrl}`, JSON.stringify(noteModel));
    }

    public getSingleBarChartData(profileList: string[], traitId: string) {

        const profileId = $("#ProfileId").val() as string;
        const profileListUrl = GarudaApi.populateProfileListUrl(profileList);

        const url: string = `${this.grafSingleGetBarChartData}/?traitId=${traitId}${profileListUrl}&profileId=${profileId}`;
        return this.get(url);
    }

    private static populateProfileListUrl(profileList: string[] ) {
        let profileListUrl = "";

        for (const id in profileList) {

            if (profileList.hasOwnProperty(id)) {
                profileListUrl += `&profileList=${profileList[id]}`;
            }
        }

        return profileListUrl;
    }

    public getEnergyGraphic(profileId: string, miniature: boolean) {
        const url = `${this.EnergyGetUrl}/?profileId=${profileId}&miniature=${miniature}`;
        return this.get(url);
    }

    public getEnergyWithHeaderGraphic(profileId: string, miniature: boolean) {
        const url = `${this.EnergyWithHeaderGetUrl}/?profileId=${profileId}&miniature=${miniature}`;
        return this.get(url);
    }

    public getBarometerData(profileId: string, barometerType: string, useCaption: boolean, subtool: SubToolEnum) {
        const url = `${this.barometerGetUrl}/?profileId=${profileId}&barometer=${barometerType}&useCaption=${useCaption}&subToolEnum=${subtool}`;
        return this.get(url);
    }

    public getBarometerWithHeader(profileId: string, barometerType: string, subtool: SubToolEnum) {
        const url = `${this.barometerWithHeaderGetUrl}/?profileId=${profileId}&barometer=${barometerType}&subToolEnum=${subtool}`;
        return this.get(url);
    }

    public getBarometerGroup(profileId: string, barometerType: string, subtool: SubToolEnum) {
        const url = `${this.barometerGroupGetUrl}/?profileId=${profileId}&barometer=${barometerType}&subToolEnum=${subtool}`;
        return this.get(url);
    }

    public getWeightChart(personprofileId: string, traitId: string) {
        const url = `${this.weightChartUrl}/?personprofileId=${personprofileId}&traitId=${traitId}`;
        return this.get(url);
    }

    public getKeywordChart(personprofileId: string, firstKeywordId: string, secondKeywordId: string) {
        const url = `${this.keywordChartUrl}/?personprofileId=${personprofileId}&firstKeywordId=${firstKeywordId}&secondKeywordId=${secondKeywordId}`;
        return this.get(url);
    }

    public getChartWithHeader(profileId: string) {
        const url = `${this.chartWithHeaderGetUrl}/?profileId=${profileId}`;
        return this.get(url);
    }

    public updateRespondent(respondentModel: any) {
        return this.post(`${this.baseUrl}${this.respondentUpdateEndpoint}`, JSON.stringify(respondentModel));
    }

    public saveRespondent(respondentModel: RespondentModel) : Promise<CreateRepondentReponseModel>{
        return this.post(`${this.baseUrl}${this.respondentPostEndpoint}`, JSON.stringify(respondentModel));
    }

    public getCompareModalBody(subtoolsToExclude : SubToolEnum[], tool : ToolEnum, includeGroup: boolean = false) {
        const parameters = this.arrayToGetUrl("subtoolsToExclude", true, subtoolsToExclude?.map<string>(x => x.toString()))
        return this.get(`${this.baseUrl}${this.getCompareModalBodyEndpoint}/${parameters}&tool=${tool}&includeGroup=${includeGroup}`);
    }

    public updateGenerelProfilesetting(key : string, value: boolean) {
        let url = `${this.updateGenerelProfileSettingUrl}?key=${key}&value=${value}`;
        return this.post(url, null);
    }

    public updateGenerelProfileSettingList(settings: ProfileSettingModel[]) {
        let url = `${this.updateGenerelProfileSettingListUrl}`;
        return this.post(url, JSON.stringify(settings.map(s => ({ Key: s.key, Value: JSON.stringify(s.value) }))));
    }

    public updateGenerelProfilesettingFromGroup(key : string, value: boolean) {
        let url = `${this.updateGenerelProfileSettingFromBenchmarkUrl}?benchmarkGroupEnum=${key}&value=${value}`;
        return this.post(url, null);
    }

    public updateSelectedLanguage(languageId: string) {
        return this.post(this.languageSettingPostUrl, JSON.stringify(languageId));
    }

    public updateSelectedScale(scaleId: string) {
        return this.post(this.scaleSettingPostUrl, JSON.stringify(scaleId));
    }

    public getAdjustmentModal(profileId: string, hideCompare: boolean) {
        return this.get(`${this.baseUrl}${this.getAdjustmentModalContent}?profileId=${profileId}&hideCompare=${hideCompare}`);
    }

    public getCreateGroupProfileModal() {
        return this.get(`${this.baseUrl}${this.getCreateGroupProfileModalContent}`);
    }

    public getGroupProfileEditModal() {
        return this.get(`${this.baseUrl}${this.getGroupProfileEditModalContent}`);
    }

    public getGroupProfile(profileId: string): Promise<GroupProfileModel> {
        return this.get(`${this.baseUrl}${this.getGroupProfileEndpoint}?profileId=${profileId}`);
    }

    public getAdjustmentProfileSettings(profileId: string) : Promise<ProfileSettingModel[]> {
        return this.get(`${this.baseUrl}${this.getAdjustmentSettings}?profileId=${profileId}`);
    }

    public getAdjustmentProfileSetting(key: string,): Promise<string> {
        return this.get(`${this.baseUrl}${this.getAdjustmentSetting}?key=${key}`);
    }

    public getProfilesAttached(profileId: string, profileSubtoolEnum : SubToolEnum, subtoolId: string, subtoolName: string) {
        return this.get(`${this.baseUrl}${this.personProfilGetProfilesAttached}/?profileId=${profileId}&profileSubtoolEnum=${profileSubtoolEnum}&subtoolEnum=${subtoolId}&subtoolName=${subtoolName}`);
    }

    public getInterpretationPartial(profileId: string, traitId: string, profileAttachedIds: string[], selectedThreadId: string, selectedTabCaption: string) {
        const profileListUrl = this.arrayToGetUrl("profileIds",false, profileAttachedIds);

        let additionalQueryParams = "";
        if (selectedThreadId) {
            additionalQueryParams = `&selectedThreadId=${selectedThreadId}`;
        }

        if (traitId) {
            additionalQueryParams +=  `&traitId=${traitId}`;
        }

        if (selectedTabCaption) {
            additionalQueryParams += `&selectedTabCaption=${selectedTabCaption}`
        }

        return this.get(`${this.baseUrl}${this.profileInterpretationEndpoint}/?profileId=${profileId}${additionalQueryParams}${profileListUrl}`);
    }

    public updateOwnCommentReplacesInterpretation(settingsId: string, state: boolean) {
        const model = { settingsId: settingsId, replace: state }

        return this.post(`${this.baseUrl}${this.profileSettingEndpoint}`, JSON.stringify(model));
    }

    public getCombinedTraitsPartial(profileId: string, combinedIdentifier: string) {
        return this.get(`${this.baseUrl}${this.profileCombinedTraitsEndpoint}/?profileId=${profileId}&combinedIdentifier=${combinedIdentifier}`);
    }

    public getCombinedTraitsGraphPartial(traitId: string[]): Promise<any> {
        let url: string = `${ this.baseUrl }${ this.profileCombinedTraitsGraphUrl }/?}`;
        if (traitId) {
            for (const id of traitId) {
                url += `&traitId=${id}`;
            }
        }
        return this.get(url);
    }

    public getStatementPartial(profileId: string, traitId: string, profileAttachedIds: string[]) {
        const profileListUrl = this.arrayToGetUrl("profileIds",false, profileAttachedIds);
        return this.get(`${this.baseUrl}${this.statementContentUrl}/?traitId=${traitId}&primaryProfileId=${profileId}${profileListUrl}`);
    }

    public getStatementAnalysisInsight(profileId: string, typeId: string) {
        return this.get(`${this.baseUrl}${this.statementAnalysisInsightContentUrl}/?profileId=${profileId}&typeId=${typeId}`);
    }

    public getFpStatementPartial(profileId: string, selectedThreadId: string) {

        let selectedThreadIdUrl = "";
        if (selectedThreadId) {
            selectedThreadIdUrl = `&selectedThreadId=${selectedThreadId}`;
        }

        return this.get(`${this.baseUrl}${this.statementFpContentUrl}/?profileId=${profileId}${selectedThreadIdUrl}`);
    }

    public getGroupProfileStatementPartial(profileIds: string[], selectedThreadId: string, showPercentage: boolean) {

        let selectedThreadIdUrl = "";
        if (selectedThreadId) {
            selectedThreadIdUrl = `&selectedThreadId=${selectedThreadId}`;
        }

        const profileListUrl = this.arrayToGetUrl("profileIds", true, profileIds);
        return this.get(`${this.baseUrl}${this.focusGroupStatementContentEndpoint}/${profileListUrl}${selectedThreadIdUrl}&showPercentage=${showPercentage}`);
    }

    public postAttachedProfiles(profileId: string, attachedProfiles: AttachedProfilePostModel[]) {

        const model = {
            attachedProfiles: attachedProfiles
        }

        return this.post(`${this.baseUrl}${this.personProfilPostProfilesAttached}/?profileId=${profileId}`, JSON.stringify(model));
    }

    public deleteAttachedProfile(attachedId: string, profilesToRemove: string[]) {
        const url = `${this.baseUrl}${this.personProfilDeleteProfileAttached}`;

        const model = {
            profileId: attachedId,
            profilesToRemove: profilesToRemove
        }

        return this.post(url, JSON.stringify(model));
    }

    public addNewAttachedProfile(profileId: string, profilesToAdd: string[]) {
        const url = `${this.baseUrl}${this.personProfilPostNewProfileAttached}`;

        const model = {
            profileId: profileId,
            profilesToAdd: profilesToAdd
        }

        return this.post(url, JSON.stringify(model));
    }

    public async getAttachedProfilesFiltered(profileId: string, selectedSubtool: string, tagFilter: string) : Promise<Array<SelectItemModel>> {
        const url = `${this.baseUrl}${this.getAttachedProfilesFilteredEndpoint}?tagFilter=${tagFilter}&profileId=${profileId}&subtoolEnum=${selectedSubtool}`;
        return await this.get(url);
    }

    public async getTemplates(toolId: string, subToolId: string, langCode: string) {
        const url = `${this.baseUrl}${this.templateListGetUrl}/?toolId=${toolId}&subtoolId=${subToolId}&language=${langCode}`;
        return await this.get(url);
    }

    public async distributeRespondent(distributionModel: RespondentDistributionModel) {
        const url = `${this.baseUrl}${this.distributeRespondentPostUrl}`;
        return await this.post(url, JSON.stringify(distributionModel));
    }

    public async getMergedTemplateId(id: string, templateId: number, toolId: string, subToolId: string, language: string, attachedProfileName : string, additionalGarudaLogikProfileId: string) {
        const url = `${this.baseUrl}${this.mergedTemplatePostUrl}`;
        const postModel = {
            profileId: id,
            templateId: templateId,
            toolId: toolId,
            subToolId: subToolId,
            language: language,
            attachedProfileName: attachedProfileName,
            additionalGarudaLogikProfileId: additionalGarudaLogikProfileId
        };

        return await this.post(url, JSON.stringify(postModel));
    }

    public async getMergedSMSTemplate(id: string, templateId: number, toolId: string, subToolId: string, language: string, attachedProfileName : string) {
        const url = `${this.baseUrl}${this.mergedSMSTemplatePostUrl}`;
        const postModel = {
            profileId: id,
            templateId: templateId,
            toolId: toolId,
            subToolId: subToolId,
            language: language,
            attachedProfileName: attachedProfileName
        };

        return await this.post(url, JSON.stringify(postModel));
    }

    public async getReportTypes(tool: ToolEnum, subtool: SubToolEnum,) {
        const url: string = `${this.baseUrl}${this.reportTypesGetUrl}?tool=${tool}&subtool=${subtool}`;
        return this.get(url);
    }

    public async getFocusPersonReportTypes(focusJobProfileId: string, focusMirrorProfileId: string, focusDreamPositionProfileId: string, focusPersonProfileIds: string[])
    {
        let url: string = `${this.baseUrl}${this.focusPersonReportTypesGetUrl}?focusJobProfileId=${focusJobProfileId}&focusMirrorProfileId=${focusMirrorProfileId}&focusDreamPositionProfileId=${focusDreamPositionProfileId}`
        for (const profileId of focusPersonProfileIds) {
            url = url.concat(`&focusPersonProfileIds=${profileId}`)
        }
        return this.get(url);
    }

    public async getJobProfilReportTypes(jobProfileId: string, mirrorProfileId: string, personProfileIds: string[]) {
        let url: string = `${this.baseUrl}${this.jobProfileReportTypesGetUrl}?jobProfileId=${jobProfileId}&mirrorProfileId=${mirrorProfileId}`
        for (const profileId of personProfileIds) {
            url = url.concat(`&personProfileIds=${profileId}`)
        }
        return this.get(url);
    }

    public async getFocusJobProfilReportTypes(jobProfileId: string, mirrorProfileId: string, personProfileIds: string[]) {
        let url: string = `${this.baseUrl}${this.focusJobProfileReportTypesGetUrl}?jobProfileId=${jobProfileId}&mirrorProfileId=${mirrorProfileId}`
        for (const profileId of personProfileIds) {
            url = url.concat(`&personProfileIds=${profileId}`)
        }
        return this.get(url);
    }

    public async getFocusMirrorFollowupReportTypes()
    {
        let url: string = `${this.baseUrl}${this.focusMirrorFollowupReportTypesGetUrl}`
        return this.get(url);
    }

    public async getPersonProfileReportTypes(jobProfileId: string, mirrorProfileId: string, referenceProfileId: string, personProfileIds: string[])
    {
        let url: string = `${this.baseUrl}${this.personProfileReportTypesGetUrl}?jobProfileId=${jobProfileId}&mirrorProfileId=${mirrorProfileId}&referenceProfileId=${referenceProfileId}`
        for (const profileId of personProfileIds) {
            url = url.concat(`&personProfileIds=${profileId}`)
        }
        return this.get(url);
    }

    public async getGroupReportContent(tool: ToolEnum) {
        return this.get(`${this.baseUrl}${this.groupReportContentGetUrl}?tool=${tool}`);
    }

    public async getMultiProfileSharing(profileIds: string[]): Promise<string> {
        const profileListUrl = this.arrayToGetUrl("profileIds", true, profileIds);
        return this.get(`${this.baseUrl}${this.multiProfileSharingGetUrl}${profileListUrl}`);
    }

    public async removeMultiProfileSharing(profileIds: string[]): Promise<string> {
        const profileListUrl = this.arrayToGetUrl("profileIds", true, profileIds);
        return this.get(`${this.baseUrl}${this.removeMultiProfileSharingGetUrl}${profileListUrl}`);
    }

    public async getProfileSharing(profileId: string) {
        return this.get(`${this.baseUrl}${this.profileSharingGetUrl}?profileid=${profileId}`);
    }

    public async shareProfileWithNewUser(userRoleModels: UserRoleModel[], email: string, name: string, timeLimit: string, profileId?: string): Promise<string> {
        const postModel = { profileId, email, name, userRoleModels, timeLimit }
        return this.post(`${this.baseUrl}${this.shareProfileWithNewUserUrl}`, JSON.stringify(postModel));
    }

    public async addProfileSharing(profileId : string, userId : string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.addProfileSharingPostUrl}`, {profileId, userId});
    }

    public async addMultiProfileSharing(profileIds : string[], userId : string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.addMultiProfileSharingPostUrl}`, {profileIds, userId});
    }

    public async inviteNewUsers(newUsersSharingModels: NewUsersSharingModel) {
        return this.post(`${this.baseUrl}${this.inviteProfileSharingUserPostUrl}`, JSON.stringify(newUsersSharingModels));
    }

    public async setProfileSharingTimeLimit(profileId: string, timelimit: string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.setTimeLimitProfileSharingUrl}`, {profileId, timelimit});
    }

    public async setMultiProfileSharingTimeLimit(profileIds: string[], timelimit: string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.setMultiTimeLimitProfileSharingUrl}`, {profileIds, timelimit});
    }

    public async setCustomizedProfileSharingTimeLimit(userId: string, profileId: string, timelimit: string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.setCustomizedTimeLimitProfileSharingUrl}`, { userId, profileId, timelimit });
    }

    public async customizeProfileSharingSharing(profileId: string, email: string, name: string, userId: string) {
        const postModel = { profileId, email, name, userId }
        return this.post(`${this.baseUrl}${this.customizeProfileSharingUrl}`, JSON.stringify(postModel));
    }

    public async customizeNewUserProfileSharing(userRoleModels: UserRoleModel[], profileId: string, email: string, name: string, timeLimit: string) {
        const postModel = { userRoleModels, profileId, email, name, timeLimit }
        return this.post(`${this.baseUrl}${this.customizeNewUserProfileSharingUrl}`, JSON.stringify(postModel));
    }

    public async deleteProfileSharing(profileId : string, userId : string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.deleteProfileSharingUrl}`, {profileId, userId});
    }

    public async updateProfileRole(profileId : string, userId : string, roleId : string) {
        return this.postAsUrlEncoded(`${this.baseUrl}${this.profileRolePostUrl}`,{profileId, userId, roleId});
    }

    public async getReportTypeDetails(tool: ToolEnum, subtool: SubToolEnum, vsSubToolEnum: SubToolEnum | null = null, variantTemplate: string | null = null, language: string | null = null, profileId: string |null = null, forGroup: boolean | null = null) {
        let url = `${this.baseUrl}${this.reportTypeGetUrl}/?tool=${tool}&subtool=${subtool}`;

        if (vsSubToolEnum !== null){
            url += `&vsSubToolEnum=${vsSubToolEnum}`;
        }

        if (variantTemplate !== null) {
            url += `&variantTemplate=${variantTemplate}`;
        }

        if (language !== null) {
            url += `&language=${language}`;
        }

        if(profileId !== null) {
            url += `&profileId=${profileId}`;
        }
        if(forGroup !== null) {
            url += `&forGroup=${forGroup}`;
        }

        return this.get(url);
    }

    public async getSpecialReportTypeDetails(profileId: string, reportType: string) {
        return this.get(`${this.baseUrl}${this.specialReportTypeGetUrl}/?profileId=${profileId}&reportType=${reportType}`);
    }

    public async getRespondentMergeModal(respondentIds: string[]): Promise<string> {
        const profileListUrl = this.arrayToGetUrl("respondentIds", true, respondentIds);
        return this.get(`${this.baseUrl}${this.respondentMergeModalUrl}${profileListUrl}`);
    }

    public async getProfileSelectorModal(respondentId: string): Promise<string> {
        return this.get(`${this.baseUrl}${this.profileSelectorModalUrl}/?respondentId=${respondentId}`);
    }

    public async requestReport(profileIds: string[], reportLanguage: string, plurality: PluralityEnum, settings: any,
                               saveSettings: boolean, reportName: string, isVersusReport: boolean, variant?: string,
                               reportType?: string, reportTemplateVariant?: string, forNotes: boolean = false, forGroup: boolean = false) {
        const requestReportPostModel = {
            profileIds: profileIds,
            language: reportLanguage,
            plurality: plurality,
            saveSettings: saveSettings,
            reportSettings: settings,
            reportName: reportName,
            isVersusReport: isVersusReport,
            variant: variant,
            reportType: reportType,
            reportTemplateVariant: reportTemplateVariant,
            forNotes: forNotes,
            forGroup: forGroup
        };

        return this.post(`${this.baseUrl}${this.reportRequestPostUrl}`, JSON.stringify(requestReportPostModel));
    }

    public async requestSendReportMail(reportId: string, emails: string[], language: string, reportName: string) {
        const requestSendReportMailModel = {
            reportId: reportId,
            emails: emails,
            language: language,
            reportName: reportName
        };

        return this.post(`${this.baseUrl}${this.requestSendReportMailPostUrl}`, JSON.stringify(requestSendReportMailModel));
    }

    public async reportStatus(reportIds: string[]) {
        const url = `${this.baseUrl}${this.reportStatusUrl}?reportIds=${reportIds.join("&reportIds=",)}`;
        return this.get(url);
    }

    public async reportNotificationContent() {
        const url = `${this.baseUrl}${this.reportNotificationContentUrl}`;
        return this.get(url);
    }

    public async reportNotifications() {
        const url = `${this.baseUrl}${this.reportNotificationsUrl}`;
        return this.get(url);
    }

    public async getReportMailModal(reportId: string) {
        const url = `${this.baseUrl}${this.getReportMailModalUrl}?reportId=${reportId}`;
        return this.get(url);
    }

    public async deleteReportNotifications() {
        const url = `${this.baseUrl}${this.reportNotificationsDeleteUrl}`;
        return this.get(url);
    }

    public async getFile(url: string) {
        return await this.getFileAsBlob(url);
    }

    public async getEditRespondentModal(profileId: string, respondentId: string) {
        return this.get(`${this.baseUrl}${this.getEditRespondentModalUrl}/?respondentId=${respondentId}&profileId=${profileId}`);
    }

    public async getEditRespondentTagsModal() {
        return this.get(this.getEditRespondentTagsModalUrl);
    }

    public async getTransferOwnershipModal(profileIds: string[]) {
        return this.post(`${this.baseUrl}${this.getTransferOwnershipModalUrl}`, JSON.stringify(profileIds));
    }

    public getCompanyTags(tagEnum : TagEnum) : Promise<SelectItemModel[]> {
        return this.get(`${this.baseUrl}${this.getCompanyTagsUrl}?tagType=${tagEnum}`);
    }

    public getRespondantsUserInformation(): Promise<UserInformationModel> {
        return this.get(`${this.baseUrl}${this.getUserInformationUrl}`);
    }

    public getExistingRespondents() : Promise<RespondentExistingModel[]> {
        return this.get(`${this.baseUrl}${this.getExistingRespondentsUrl}`)
    }

    public getRespondentProfilesToSplit(respondentId: string) : Promise<RespondentProfilesSplitViewModel> {
        return this.get(`${this.baseUrl}${this.getRespondentProfilesToSplitUrl}/?respondentId=${respondentId}`)
    }

    public async splitRespondentProfilePost(splitRespondentProfilePostModel: SplitRespondentProfilePostModel) {
        return this.post(`${this.baseUrl}${this.splitRespondentProfilePostUrl}`, JSON.stringify(splitRespondentProfilePostModel));
    }

    async updateRespondentsTags(tagsEditViewModel: MultiRespondentEditModel) {
        const url = `${this.baseUrl}${this.editRespondentsTagsUrl}`;
        return await this.post(url, JSON.stringify(tagsEditViewModel));
    }

    async getTransferOwnershipUserComponent(transferOwnershipModel: TransferOwnershipPostModel): Promise<string> {
        const url = `${this.baseUrl}${this.transferOwnershipUserUrl}`;
        return await this.post(url, JSON.stringify(transferOwnershipModel));
    }

    async transferOwnership(transferOwnershipModel: TransferOwnershipPostModel): Promise<string> {
        const url = `${this.baseUrl}${this.transferOwnershipUrl}`;
        return await this.post(url, JSON.stringify(transferOwnershipModel));
    }

    public async getRespondentListPartial(tool: ToolEnum, subTool: SubToolEnum, limit: number) {
        return this.get(`${this.baseUrl}${this.getRespondentListPartialUrl}?tool=${tool}&subtool=${subTool}&limit=${limit}`);
    }

    public async getPersonprofileListJson(toolIdentifier: string, subToolIdentifier: string) {
        return this.get(`${this.baseUrl}${this.getPersonprofileAssociationList}?toolIdentifier=${toolIdentifier}&subToolIdentifier=${subToolIdentifier}`);
    }

    public async getUserInformation() {
        return await this.get(`${this.baseUrl}${this.getUserInformationHelperUrl}`);
    }

    public async patchUserInformation(userInformation: UserInformationPostModel) {
        const url = `${this.baseUrl}${this.postUserInformationUrl}`;
        return await this.post(url,JSON.stringify(userInformation));
    }

    public async postForgotPassword() {
        const url = `${this.baseUrl}${this.forgotPasswordUrl}`;
        return await this.post(url, {});
    }

    public async postEnableTwoFactor(enableTwoFactor: boolean, twoFactorInput:string, twofactorCodeGenerated:string) {
        const url = `${this.baseUrl}${this.enableTwoFactorUrl}?enableTwoFactor=${enableTwoFactor}&twoFactorInput=${twoFactorInput}&twofactorCodeGenerated=${twofactorCodeGenerated}`;
        return await this.post(url, {});
    }

    public async postEnableTwoFactorSendInitSms():Promise<string> {
        const url = `${this.baseUrl}${this.enableTwoFactorSendConfirmSmsUrl}`;
        return await this.post(url, {});
    }

    public async getGlobalDashboardData(daysFilter: number): Promise<CopDashboardModel> {
        const url = `${this.getGlobalDashboardDataUrl}?days=${daysFilter}`;
        return await this.get(url);
    }

    public async getGlobalDashboardAdjustPurposeModal(): Promise<string> {
        const url = `${this.getGlobalDashboardAdjustPurposeModalUrl}`;
        return await this.get(url);
    }

    public async getGlobalDashboardPurposePartial(days: number, countForCompany: boolean, usePercentage: boolean): Promise<string> {
        const url = `${this.getGlobalDashboardPurposePartialUrl}?countForCompany=${countForCompany}&usePercentage=${usePercentage}&days=${days}`;
        return await this.get(url);
    }

    public async getCopDashboardData(daysFilter: number): Promise<CopDashboardModel> {
        const url = `${this.getCopDashboardDataUrl}?days=${daysFilter}`;
        return await this.get(url);
    }

    public async mergeRespondentsPost(respondentMergePostModel: MergeRespondentsPostModel) {
        return this.post(`${this.baseUrl}${this.mergeRespondentsPostUrl}`, JSON.stringify(respondentMergePostModel));
    }

    public async duplicateProfilePost(profileIds: string[]) {
        return this.post(`${this.baseUrl}${this.duplicateProfilePostUrl}`, JSON.stringify(profileIds));
    }

    public async getOnboardingProfileListPartial(showHidden: boolean): Promise<string> {
        return this.get(`${this.baseUrl}${this.getOnboardingProfileListPartialUrl}`, {
            showHidden: showHidden
        });
    }

    public async setOnboardingProfileHidden(profileId: string, hidden: boolean) {
        return this.post(`${this.baseUrl}${this.setOnboardingProfileHiddenUrl}`, JSON.stringify({
            profileId: profileId,
            hide: hidden
        }));
    }

    public async getFocusProfiles(): Promise<FocusProfileModel[]> {
        const url = `${this.baseUrl}${this.getFocusProfilesEndpoint}`;
        return await this.get(url);
    }

    public async getProfilesForTag(tag: string): Promise<{tag: string, profiles: FocusProfileModel[]}> {
        const url = `${this.baseUrl}${this.getProfileCountForTagEndpoint}?tag=${encodeURIComponent(tag)}`;
        return await this.get(url);
    }

    public async patchSvgClicked(profileId: string, type: string, trait: number, addScore: number) {
        return this.post(`${this.patchSvgClick}`, JSON.stringify({
            profileId: profileId,
            type: type,
            trait: trait,
            addScore: addScore
        }));
    }

    public async GetGarudaGraphModal() {
        const url = `${this.baseUrl}${this.getGarudaGraphModal}`;
        return await this.get(url);
    }

    public createGroupProfile(groupModel: GroupProfileModel) : Promise<any> {
        return this.post(`${this.baseUrl}${this.groupProfilePostEndpoint}`, JSON.stringify(groupModel));
    }

    public updateGroupProfile(groupModel: GroupProfileModel) : Promise<any> {
        return this.post(`${this.baseUrl}${this.groupProfilePatchEndpoint}`, JSON.stringify(groupModel));
    }

    public async getProfilesInGroupProfile(profileId: string) : Promise<string[]> {
        const url = `${this.baseUrl}${this.profilesInGroupProfileEndpoint}?profileId=${profileId}`;
        return await this.get(url);
    }

    public async getFpKeywordAnalysisContent(profileId: string, selectedThreadId: string) {
        let selectedThreadIdUrl = "";
        if (selectedThreadId) {
            selectedThreadIdUrl = `&selectedThreadId=${selectedThreadId}`;
        }
        const url = `${this.baseUrl}${this.focusProfileKeywordAnalysisContentEndpoint}?profileId=${profileId}${selectedThreadIdUrl}`;
        return await this.get(url);
    }

    public getPersonProfileInformationPartial(profileId: string, subTool: SubToolEnum) {
        return this.get(`${this.baseUrl}${this.personProfileInformationContentUrl}/?profileId=${profileId}&subTool=${subTool}`);
    }

    public getDownloadReportUrl(reportId: string, mimeType: string, isAnonymous= false): string {
        const url = `${this.baseUrl}${this.reportDownloadUrl}/?reportId=${reportId}&mimeType=${mimeType}`;
        return url;
    }

    public async getEventLog(profileId: string, isProfile: boolean, isModal: boolean = false): Promise<string> {
        return await this.get(`${this.baseUrl}${this.eventLogProfileUrl}?profileId=${profileId}&isProfile=${isProfile}&isModal=${isModal}`);
    }

    public async postErrorToApi(errorMessage: string): Promise<any> {
        return this.post(`${this.baseUrl}${this.reportErrorToApi}`, JSON.stringify(errorMessage));
    }

    private arrayToGetUrl(bindingProperty: string, first: boolean, values: Array<string>): string {

        let result = first ? `?${bindingProperty}=` : "";

        values?.forEach((value, index) => {
            if (index === 0 && result) {
                result += value;
            }
            else {
                result += `&${bindingProperty}=${value}`;
            }
        });

        return result;
    }

    private static getAntiForgeryToken(): string {
        return $("input:hidden[name='__RequestVerificationToken']").val() as string;
    }

    private async get(url: string, payload?: any) {
        return $.ajax({
            method: "GET",
            url: url,
            data: payload
        }).fail((a, b, c) => console.log("error?", b));
    }

    private async post(url: string, payload: any) {
        return $.ajax({
            url: url,
            method: "POST",
            data: payload,
            contentType: "application/json;charset=utf-8",
            headers: {
                RequestVerificationToken: GarudaApi.getAntiForgeryToken()
            }
        });
    }

    private async postAsUrlEncoded(url: string, payload: any) {
        return $.ajax({
            url: url,
            method: "POST",
            data: payload,
            headers: {
                RequestVerificationToken: GarudaApi.getAntiForgeryToken()
            }
        });
    }

    private async getFileAsBlob(url: string) {
        return $.ajax({
            url: url,
            cache: false,
            xhr: function () {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob'
                return xhr;
            },
            success: function (data) {
                return data;
            },
            error: function () {

            }
        });
    }
}
